import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

type stackNav = {
    to: string
}

function BackNav(props: stackNav){
    return (
        <div className="pl-[5%]">
            <Link
                to={props.to}
            >
                <ArrowLeftIcon className="hover:opacity-50 h-10 w-10 md:h-16 md:w-16 text-customBlueGray" />
            </Link>
                
        </div>
    )
}

export default BackNav;