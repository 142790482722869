import { useState } from 'react';
import Button from './Button';
import { commitToDatabase } from '../util/db';


interface MessageDetails {
  name?: string;
  email?: string;
  pnumber?: string;
  message?: string;
}

function ContactForm() {
  const [messageDetails, setMessageDetails] = useState<MessageDetails>({
  });

  function onChangeFunc(category: string, e: any) {
    let updatedValue = {};
    if (category === "name") {
      updatedValue = {name:e.target.value};
    } else if (category === "email") {
      updatedValue = {email:e.target.value};
    } else if (category === "pnumber"){
      updatedValue = {pnumber:e.target.value};
    } else if (category === "message"){
      updatedValue = {message:e.target.value};
    } else {
      updatedValue = ""
    }
    setMessageDetails(messageDetails => ({
      ...messageDetails,
      ...updatedValue
    }))
  }

  async function submitDetails(e: any) {
    e.preventDefault();
    try {
      const data = await commitToDatabase(messageDetails);
      alert('successfully submitted');
    } catch (e) {
      alert('error submitting request');
    }
  }

  return (
    <div className='bg-customBlueGray overflow-auto p-6 h-[100%]'>
      <form onSubmit={submitDetails}>
        <br/>

        <label className='py-4 text-white'>Your Name
        <br/>
          <input
            className='my-4 w-[100%] h-[2rem] text-black'
            placeholder='' 
            type="text" 
            value={messageDetails.name}
            required
            onChange={(e) => onChangeFunc("name",e)}
          />
        </label>
        <br/>

        <label className='py-4 text-white'>Your Email
        <br/>
          <input
            className='my-4 w-[100%] h-[2rem] text-black'
            placeholder=' abc@example.com' 
            type="email" 
            value={messageDetails.email}
            required
            onChange={(e) => onChangeFunc("email",e)}
          />
        </label>
        <br/>

        <label className='py-4 text-white'>Your Phone Number
        <br/>
          <input
            className='my-4 w-[100%] h-[2rem] text-black'
            placeholder=' +0000000000000' 
            pattern="^\+?\d{0,13}"
            type="text"
            value={messageDetails.pnumber}
            required
            onChange={(e) => onChangeFunc("pnumber",e)}
          />
        </label>
        <br/>

        <label className='py-4 text-white'>Your Message
        <br/>
          <textarea
            className='my-4 w-[100%] h-[7rem] lg:h-[5rem] text-black'
            placeholder='' 
            value={messageDetails.message}
            required
            onChange={(e) => onChangeFunc("message",e)}
          />
        </label>
        <br/>

      <div>
        <input className="bg-customYellow hover:bg-customYellow text-customBlueGray lg:text-[24px] font-light py-2 px-6 rounded-full sm:mt-[0.5rem] active:bg-customCard" type="submit" value="Submit"></input>
      </div>
      </form>

      
    </div>


    
  )
}

export default ContactForm;
