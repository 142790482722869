import { title } from "process";
import Button from "../components/Button";
import ContactType from "../components/ContactType";
import Navibar from "../components/Navibar";
import BackNav from "../components/BackNav";
import ContactForm from "../components/ContactForm";
import { contact_items } from "../util/data";


function Contact(){
    return <div className="lg:px-4">
        <Navibar/>
        <div className="my-4">
        <BackNav to='/about'/>
        </div>
        <div className="flex flex-col md:flex-row px-6 md:px-10 lg:px-20">
            <div className="basis-1/2 px-4">
                <h1 className="text-4xl font-light py-3 text-customBlueGray">Get in touch with us</h1>
                <p className="text-justify font-light py-3 text-customBlueGray">We're here to help you navigate your data challenges and opportunities. Whether you're interested in enhancing your data management or processing, exploring analytics/AI solutions, or just have questions about our services, our team is ready to assist. Reach out to us through the form below, email, or give us a call. Let's connect and transform your data into value together.</p>
                <div className="">
                    <ContactType icon={contact_items[0].icon} title={contact_items[0].title} info1={contact_items[0].info1} info2={contact_items[0].info2}/> 
                    <ContactType icon={contact_items[1].icon} title={contact_items[1].title} info1={contact_items[1].info1} info2={contact_items[1].info2}/> 
                    <ContactType icon={contact_items[2].icon} title={contact_items[2].title} info1={contact_items[2].info1} info2={contact_items[2].info2}/> 
                </div>
            </div>
            <div className=" my-4 md:my-0 md:mx-6 basis-1/2">
                <ContactForm/>
            </div> 
        </div>
        
    </div>
}

export default Contact;