import { useEffect, useRef, useState } from "react";
import Navibar from "../components/Navibar";
import ServiceCard from "../components/ServiceCard";
// import { Carousel } from 'react-responsive-carousel';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styled from 'styled-components';
import Modal from "react-overlays/Modal";
import BackNav from "../components/BackNav";
import ForwardNav from "../components/ForwardNav";
import ModalServiceCard from "../components/ModalServiceCard";
import { service_items } from "../util/data";


const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1500 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 1500, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 602 },
      items: 3
    },
    largeMobile: {
      breakpoint: { max: 602, min: 400 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 400, min: 0 },
      items: 1
    }
  };

const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.75;
`;

const ModalObject = styled(Modal)`
  position: fixed;
  width: 100%;
  z-index: 1040;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  overflow: auto

  @media (min-width: 606px) {
    width: 90%;
  }

  @media (min-width: 900px) {
    width: 80%;
  }


`;

type item_object =  {
  id: number;
  title: string;
  image: string;
  summary: string;
  description: string;
}

function Services(){
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(-1);

    const renderBackdrop = (props: any) => <Backdrop {...props} />;

    const openModalWithItem = (item: any) => {
        setSelectedItem(item.id);
        setIsModalOpen(true);

    };

    function modalCloseHandler(){
        setIsModalOpen(false)
    }

  function displayRightModalServiceCardSize(customStyle: string = ""){
    return service_items.map((item) => (
      item.id === selectedItem && (
      <div key={selectedItem} className="">
            <ModalServiceCard title={item.title} image={item.image} description={item.description} customStyle={customStyle} closeHandle={modalCloseHandler}/>
        </div>)
    ))
  }

    let display = <div>
        {displayRightModalServiceCardSize()}
      </div>

    if (selectedItem == 0 || selectedItem == 1){
      display = <div>
        {displayRightModalServiceCardSize("h-[13rem] w-[22rem]")}
      </div>
    }

    return (
        <div>
            <Navibar/>
            <div className=" ml-[3%]">
            <BackNav to='/benefits'/>
            </div>
            <div className="flex flex-row justify-center items-center">
                <h2 className="my-[3%] text-[20px] md:text-[30px] text-center text-customBlueGray font-light">We offer a wide range of services:</h2>
            </div>

            <Carousel 
                swipeable={true}
                // autoPlay
                draggable={false}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                // infinite={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                pauseOnHover
                containerClass=""
                // removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                itemClass="p-[2%]"
            >
                    {service_items.map((item, index) => (
                            <div key={index} className=""
                                >
                                <ServiceCard title={item.title} image={item.image} summary={item.summary} onClickFunc={() => openModalWithItem(item)}/>
                            </div>
                        ))}
            </Carousel>

            <ForwardNav to='/about'/>
            
            <ModalObject
            className="rounded-3xl"
                show={isModalOpen}
                onHide={modalCloseHandler}
                renderBackdrop={renderBackdrop}
            >
              
                {display}
            </ModalObject>
            
        </div>
    )
}


export default Services;