import {ChevronDoubleRightIcon} from "@heroicons/react/20/solid"
import { Link } from "react-router-dom";

type stackNav = {
    to: string
}
function ForwardNav(props: stackNav) {
    return (
        <div className="pr-[10%] pb-4">
                <Link
                    to={props.to}
                >
                <ChevronDoubleRightIcon className="hover:opacity-50 h-10 w-10 md:h-20 md:w-20 text-customBlueGray float-right mb-10" />
                </Link>
                
        </div>
    )
}

export default ForwardNav;