import { Disclosure, } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Button from './Button'
import { Link, useLocation } from 'react-router-dom'
import logomain from "../media/logomain.png";




function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }


function Navibar(){
    const location = useLocation().pathname;
    console.log(location)

    const navigation = [
      { name: 'Home', href: '/', current: false },
      { name: 'Benefits', href: '/benefits', current: false },
      { name: 'Services', href: '/services', current: false },
      { name: 'About', href: '/about', current: false },
      { name: 'Contact', href: '/contact', current: false },
    ]


    return (
    <Disclosure as="nav" className="bg-white py-4 px-2">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-customBlueGray hover:bg-customBlueGray hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center ">
                {/* items-center justify-center items-stretch sm:justify-start"> */}
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-10 md:h-12 w-auto"
                    src={logomain}
                    alt="Tiptier"
                  />
                  <Link
                    to='/'
                  >
                    <h1 className="text-customBlueGray font-light text-[25px] lg:text-[35px] float-left px-2">TIPTIER</h1>
                  </Link>
                  
                </div>
                <div className="hidden sm:mr-6 ml-5 sm:mx-auto sm:inline sm:mt-[0.5rem]">
                  <div className="flex items-center space-x-4">
                    {navigation.map((item, index) => (

                      <div onMouseEnter={() => item.current = true}>
                          <Link
                            to={item.href}
                            key={item.name}
                            className={classNames(
                              item.current ? 'bg-customBlueGray text-white' : 'text-customBlueGray hover:bg-customBlueGray hover:text-white',
                              'rounded-md px-3 py-2 text-[12px] md:text-[15px] lg:text-[18px] xl:text-[22px] font-light'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </Link>
                        </div>
                      
                    ))}
                  </div>
                </div>
                <div className="ml-auto mr-14 sm:mr-0 hidden ">
                  <Link
                        to="/contact"
                    >
                      <Button title="Get in touch" customStyle="text-[15px] lg:text-[24px]"/>
                    </Link>
                </div>
              </div>
              
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <div onMouseEnter={() => item.current = true}>
                  <Link
                
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current ? 'text-left bg-customBlueGray text-white' : 'text-left text-customBlueGray hover:bg-customBlueGray hover:text-white',
                    'block rounded-md px-3 py-2 text-sm font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                  </Link>
                </div>
                
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default Navibar;
