import {XMarkIcon} from "@heroicons/react/20/solid"

type item_object =  {
    title: string;
    image: string;
    description: string;
    customStyle: any;
    closeHandle: () => void;
    
  }
function ModalServiceCard (prop: item_object) {
    return <div className="px-1">
        <div>
        <XMarkIcon className="hover:opacity-50 h-8 w-8 md:h-8 md:w-8 text-customBlueGray ml-auto" onClick={prop.closeHandle}/>
        </div>
       <h1 className="text-center text-lg sm:text-xl text-customBlueGray p-4">{prop.title}</h1>
    
        <div className="flex flex-col md:flex-row space-around justify-center items-center font-light">
            <div className="basis-1/2 justify-center items-center flex">
             <img src={prop.image} className={` h-[18rem] w-[20rem] sm:h-[20rem] sm:w-[22rem] lg:h-[22rem] lg:w-[25rem] xl:h-[30rem] xl:w-[35rem] ${prop.customStyle}`}></img>

            </div>
            <div className="xl:px-20 basis-1/2">
                <p className="text-customBlueGray text-sm sm:text-md text-justify text-customBlueGray">{prop.description}</p>  
            </div>       
        </div> 
    </div>
    
    
}

export default ModalServiceCard;