

type clientInfo = {
    image: string;
    company: string;
    deed: string;
}
function ClientCard(props: clientInfo){
    return <div className="w-[15rem] m-6 overflow-auto">
        <div className="w-[240px] h-[200px] flex flex-row items-center">
            <img className="" src={props.image}></img>
        </div>
        
        <div className="p-4 bg-customCard flex flex-col items-center rounded-2xl">
            <h1 className="py-4 font-light text-xl text-customBlueGray">{props.company}</h1>
            <p className="text-customBlueGray text-justify">{props.deed}</p>
        </div>
    </div>
}

export default ClientCard;