import symphony from "../media/symphony.png";
import bravely from "../media/bravely.png";
import andela from "../media/andela.png";
import benefit1 from "../media/benefit1.jpg";
import benefit2 from "../media/benefit2.jpg";
import benefit3 from "../media/benefit3.jpg";
import phone from "../media/phone.jpg";
import gps from "../media/gps.jpg";
import mail from "../media/mail.jpg";
import services1 from "../media/services1.jpg";
import services2 from "../media/services2.jpg";
import services3 from "../media/services3.jpg";
import services4 from "../media/services4.jpg";
import services5 from "../media/services5.jpg";
import services6 from "../media/services6.jpg";
import services7 from "../media/services7.jpg";
// import services8 from "../media/services8.jpg";
import services9 from "../media/services9.jpg";


export const client_items = [
    {
      "id": 0,
      "image": symphony,
      "company": "Symphony Solutions",
      "deed": "Built an observerbility service making use of REST frameworks and data streaming infrastructure",
    },
    {
        "id": 1,
        "image": bravely,
        "company": "Bravely",
        "deed": "Built robust data pipelines for extracting, transforming, validating and storing diverse datasets",
    },
    {
        "id": 2,
        "image": andela,
        "company": "Andela",
        "deed": "Worked with clients building datawarehouses and both batch and near realtime pipelines for varied usecases",
    },
]

type Benefit = {
    imgsrc: string;
    title: string;
    summary: string;
    description: string;
};

export const benefitData = [
    {cardId: 0, }, 
    {cardId: 1, }, 
    {cardId: 2, }, 
]

export const benefits_description: Record<number, Benefit> = {
    0: {
            imgsrc: benefit1, 
            title: "Data Value Generation", 
            summary: "We build systems that enable you maximize value in your data",
            description: "Empower your business through data, we make use of advanced tools and techniques to build analytics, data infrastructures and AI systems to enable automation, decision making and innovation in your business, giving you advantage over your competitors. We dig deep to find usecases that would provide both short and long term benefits to your business"
        },
    1: {
            imgsrc: benefit2, 
            title: "Data Performance Optimization", 
            summary: "We help improve data performance in terms of speed, space and efficiency",
            description: "Enhance your data performance with our expertise. We specialize in optimizing the time to completion of data processes, minimizing the memory costs, and improving efficiency of algorithms used, ensuring your data delivers seamlessly. Our tailored solutions maximize the potential of your data, offering innovation and reliability. Elevate your business data capabilities with our services." 
        },
    2: {
            imgsrc: benefit3, 
            title: "Data Integration", 
            summary: "We build tools to extract, migrate and combine data from different sources",
            description: "Sink your data with our data harmonization infrastructure. We excel in collecting, migrating, collating and storing data from diverse sources. Our solutions facilitate a seamless flow of data from different systems at a near real time capability. Trust us to transform your data integration challenges into opportunities for growth."
        }
}

export const contact_items = [
    {
      "id": 0,
      "icon": gps,
      "title": "Location",
      "info1": "We are a global remote based company",
      "info2": "Remote, Lagos Nigeria",
    },
    {
        "id": 1,
        "icon": phone,
        "title": "Phone Number",
        "info1": "Reach us on the below number",
        "info2": "+234 813 583 6143",
    },
    {
        "id": 2,
        "icon": mail,
        "title": "Email Address",
        "info1": "Send us an email and we will get back to you",
        "info2": "hello@tiptier.co",
    },
]

export const service_items = [
    {
      "id": 0,
      "title": "Datawarehousing",
      "image": services1,
      "summary": "Explore advanced data management through our warehousing solutions, optimized for intelligent systems",
      "description": "Our warehousing service helps with the systematic collection, transformation, and storage of diverse data sets within a centralized repository. This integration allows businesses to leverage historical data insights for predictive analytics, enhancing decision-making and enabling the development of intelligent solutions. With our scalable and secure infrastructure, companies can explore complex queries, generate advanced reports, and implement AI-driven solutions without impacting operational systems. Embrace our service to transform your data into actionable intelligence, driving growth and harnessing the full potential of AI to maintain a competitive edge."
    },
    {
      "id": 1,
      "title": "Data Engine APIs (Batch & Realtime)",
      "image": services2,
      "summary": "Efficiently process large datasets with our data processing APIs, offering batch and real-time capabilities.",
      "description": "Our data processing APIs offers both batch and real-time processing to accommodate diverse data needs. Batch processing handles large volumes of data in chunks, ideal for non-time-sensitive tasks, ensuring thorough analysis and reporting. Real-time processing, on the other hand, deals with data instantly as it arrives, perfect for applications requiring immediate insights and actions. This dual approach allows businesses to leverage data effectively, optimizing for efficiency in operations and decision-making. Whether your need is for comprehensive analysis or instant data handling, our service ensures your data is processed accurately and swiftly, driving informed business strategies."
    }, 
    {
      "id": 2,
      "title": "Event Messaging",
      "image": services3,
      "summary": "Revolutionize data communication with our event messaging service, ensuring real-time, precise, and scalable data flow.",
      "description": "Our event messaging service facilitates real-time communication between software components through a publish-subscribe model. This system allows applications to emit events, which are then captured and distributed to interested subscribers, enabling decoupled architectures and seamless data flow. It enhances system responsiveness and scalability by ensuring that components react promptly to changes without direct dependencies. Ideal for applications requiring high levels of interactivity and data integrity, our service streamlines the development of responsive, distributed systems. Simplify complex integrations and foster a reactive ecosystem with our robust Event Messaging solution."
    }, 
    {
      "id": 3,
      "title": "Search Indexing",
      "image": services4,
      "summary": "Efficiently organize and retrieve data using advanced algorithms, enhancing search capabilities for optimal experience.",
      "description": "Search indexing transforms vast data into an easily searchable format, similar to a highly efficient library system. By analyzing data, identifying key elements, and organizing them into a searchable index, it enables quick and accurate retrieval of information. Utilizing advanced algorithms and data structures, our service scales with your data, ensuring fast and relevant search results. This process not only simplifies data discovery but empowers users to find precise information swiftly, enhancing the overall search experience. Our search indexing service is the key to unlocking the full potential of your data."
    }, 
    {
      "id": 4,
      "title": "Analytics & AI Pipelines",
      "image": services6,
      "summary": "Transform raw data into insights and intelligent systems with our simplified, efficient and powerful pipelines",
      "description": "Our service combines Analytics & AI Pipelines to turn raw data into actionable insights and intelligent solutions. The analytics pipeline processes data through stages of ingestion, cleaning, and transformation, preparing it for analysis. Simultaneously, the AI pipeline focuses on developing and deploying AI models that learn from this data to predict outcomes and discover patterns. This dual approach ensures that businesses can not only understand their current data landscape but also anticipate future trends. Simplify complex data tasks and unlock predictive insights with our comprehensive service, designed to elevate your decision-making and operational efficiency."
    }, 
    {
      "id": 5,
      "title": "Cache Implementation",
      "image": services5,
      "summary": "Boost your application's data performance and efficiency with our custom cache service, ensuring faster data access and reliability",
      "description": "Our cache implementation service optimizes data access by storing frequently accessed data in a fast-access storage layer. This approach significantly reduces data retrieval times, enhancing application performance and user experience. By intelligently managing data storage and retrieval, our caching solution minimizes database load and accelerates response times, making it ideal for dynamic, high-traffic applications. Implementing our cache system is straightforward, ensuring your applications remain efficient and scalable. Elevate your application's speed and reliability with our advanced custom cache implementation service, designed for businesses aiming for peak performance"
    }, 
    {
      "id": 6,
      "title": "Data Partitioning & Sharding",
      "image": services7,
      "summary": "Enhance database efficiency and growth handling with our partitioning & sharding service, ensuring fast, scalable data access",
      "description": "Our data partitioning & sharding service enhances database performance and scalability by dividing large datasets into manageable segments. Partitioning organizes data within a single database, while sharding distributes data across multiple databases, enabling efficient data management and retrieval. This approach not only improves query response times but also facilitates the handling of growing data volumes, ensuring your applications remain fast and reliable. Ideal for businesses experiencing rapid data growth, our service guarantees data availability and performance, empowering you to focus on innovation and growth. Embrace our solution for a scalable, high-performance data architecture."
    }, 
    // {
    //   "id": 7,
    //   "title": "A/B Testing",
    //   "image": services8,
    //   "summary": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ultrices nulla eget magna",
    //   "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ultrices nulla eget magna elementum blandit. Donec vel lacus scelerisque arcu mollis laoreet eget at leo. Phasellus dui sapien, facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ultrices nulla eget magna elementum blandit.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ultrices nulla eget magna elementum blandit. Donec vel lacus scelerisque arcu mollis laoreet eget at leo. Phasellus dui sapien, facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ultrices nulla eget magna elementum blandit"
    // },
    {
      "id": 7,
      "title": "Data Connector",
      "image": services9,
      "summary": "Simplify data integration across platforms with our data connector service, ensuring seamless flow, consistency, and accessibility",
      "description": "Our data connector service streamlines the integration of diverse data sources, enabling seamless data flow between applications, databases, and storage systems. By providing a set of tools and interfaces, our connectors facilitate the efficient transfer and synchronization of data across different platforms, ensuring data consistency and accessibility. This service simplifies the complexities of data integration, allowing businesses to focus on deriving insights and value from their data, rather than on the technicalities of data movement. Ideal for organizations looking to enhance their data infrastructure, our data connector service supports a flexible, scalable approach to data management and analytics."
    },
    
    
  ]