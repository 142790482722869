import Button from './Button';

type serviceCardProp = {
  title: string;
  image: string;
  summary: string;
  onClickFunc: () => void;
}

const ServiceCard = (prop: serviceCardProp) => {
  return (
    <div className="w-[12rem] h-[450px] bg-customCard p-6 relative rounded-2xl font-light">
        <div className="w-4 h-4 bg-customBlueGray rounded-full"></div>
        <div className='w-[144px] h-[55px]'>
          <h2 className="my-5 text-customBlueGray font-normal">{prop.title}</h2>
        </div>
        
        <img src={prop.image} className="w-[144px] h-[130px] rounded-xl"/>
        <p className="text-customBlueGray text-xs my-5 text-justify ">
          {prop.summary}
        </p>
        <div className="absolute bottom-6 left-6 hover:opacity-60" onClick={prop.onClickFunc}>
           <Button title="Learn more" customStyle="text-[13px] mb-[3px] py-3" />
        </div>
        
    </div>
  );
};

export default ServiceCard;
