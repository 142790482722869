import ClientCard from "../components/ClientCard";
import Navibar from "../components/Navibar";
import about1 from "../media/about1.jpg";
import founder from "../media/founder.jpeg";
import BackNav from "../components/BackNav";
import ForwardNav from "../components/ForwardNav";
import { client_items } from "../util/data";



function About(){
    return <div>

        <Navibar/>
        <div>
            <BackNav to='/services'/> 
        </div>
        

        <section className="flex flex-col items-center font-light">
            <div className="py-8 text-center">
               <h1 className="text-2xl text-customBlueGray">Who are we?</h1>
                <p className="text-sm text-customBlueGray">Our philosophy</p> 
            </div>
            
            <div className="flex flex-col sm:flex-row md:w-[90%] xl:w-[70%] overflow-auto px-4">
                <div className="basis-1/2 flex flex-col items-center">
                   <img className="h-[16rem] sm:h-[18rem] overflow-auto" src={about1}></img> 
                </div>
                <div className="basis-1/2 rounded-3xl bg-customCard flex flex-row items-center justify-center">
                   <p className="text-justify text-customBlueGray p-4">At Tiptier, we architect the future with advanced software for data and AI, where excellence and quality define our essence. We champion simplicity, ensuring our scalable solutions are not just durable but seamlessly extensible. Our commitment to innovation empowers clients to harness the full potential of their data, setting new benchmarks in a world that constantly evolves. Together, we build legacies that last.</p>
                </div>
            </div>
        </section>

        <section className="flex flex-col items-center my-20 font-light">
            <div className="pt-4 text-center">
                <h1 className="text-2xl text-customBlueGray">Our Clients</h1>
                    <p className="text-sm text-customBlueGray">Trusted by leading organizations</p> 
                </div> 
            <div className="sm:flex sm:flex-wrap sm:justify-center sm:items-center">
                {client_items.map((item) => <ClientCard image={item.image} company={item.company} deed={item.deed}/>) }
            </div>

        </section>

        <section className="flex flex-col items-center justify-center my-8 font-light">
            <div className="py-4 text-center ">
                <h1 className="text-2xl text-customBlueGray">Our Founder</h1>
                    <p className="text-sm text-customBlueGray">The Vision</p> 
                </div> 

            <div className="md:flex md:flex-row md:items-center md:justify-center  overflow-auto w-[80%] lg:w-[65%] xl:w-[60%] ">
                <div className="basis-1/2 flex flex-col items-center">
                   <img src={founder} className="rounded-full w-[340px] "></img>
                    <div className="py-4 text-center">
                        <h2 className="font-bold text-customBlueGray" >Ayotomiwa Salau</h2>
                        <h3 className="text-sm text-customBlueGray" >Founder, Tiptier</h3>
                    </div> 
                </div>
                
                <div className="rounded-2xl bg-customCard m-2 p-4 basis-1/2">
                    <p className="text-justify py-2 text-customBlueGray">Ayotomiwa is a data science engineer who has deep experience in building data and AI projects across different domains such as Fintech, Banking and Telcommunications. He is committed to building software infrastructure that embodies values such as excellence, quality, simplicity, scalability, durability, and extensibility. His vision extends beyond mere technological innovation; it encompasses a future where complex data systems are made accessible and efficient for businesses of all sizes. Ayotomiwa's leadership is characterized by his forward-thinking approach, aiming to democratize access to powerful AI and data analytics tools, thus enabling organizations to unlock unprecedented growth and insights.</p>
                </div>
            </div>

        </section>

        <ForwardNav to='/contact'/>

    </div>
}

export default About;