

type mediumDetails = {
    icon: string;
    title: string;
    info1: string;
    info2: string;
}

function ContactType(props: mediumDetails) {
    return <div className="flex flex-row items-left justify-left">
        <img className="h-[5rem] w-[5rem] " src={props.icon}></img>
        <div className="w-[20rem] p-4">
           <h2 className="font-bold text-customBlueGray">{props.title}</h2>
           <p className="text-customBlueGray text-sm font-light">{props.info1}</p> 
            <p className="text-customBlueGray text-sm font-light">{props.info2}</p> 
        </div>
        
    </div>
}

export default ContactType;