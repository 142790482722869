import { useState } from "react";
import Modal from "react-overlays/Modal";
import BackNav from "../components/BackNav";
import Card from "../components/Card";
import ForwardNav from "../components/ForwardNav";
import Navibar from "../components/Navibar";
import styled from 'styled-components';
import { benefitData, benefits_description } from "../util/data";
import {XMarkIcon} from "@heroicons/react/20/solid"



const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.75;
`;

const ModalObject = styled(Modal)`
  position: fixed;
  height: 85%;
  width: 100%;
  z-index: 1040;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
//   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;

  @media (min-width: 606px) {
    height: 75%;
    width: 100%;
  }

  @media (min-width: 800px) {
    width: 80%;
    height: 80%;
    padding: 50px;
  }
`;

function Benefits(){

    const [displayModal, setDisplayModal] = useState(false);
    const [cardId, setCardId] = useState(0);

    const renderBackdrop = (props: any) => <Backdrop {...props} />;


    function modalOpenHandler(item: any){
        setDisplayModal(true)
        setCardId(item.cardId)
        
    }

    function modalCloseHandler(){
        setDisplayModal(false)
    }

    return (
        <div className="relative">
            <Navibar/>
            <div className="ml-[3%]">
                <BackNav to='/'/>
            </div>
            <div className="flex flex-row justify-center items-center px-10 sm:px-20">
                <h2 className="my-[3%] text-[20px] md:text-[30px] text-center text-customBlueGray font-light">Boost impact of your data and unearth its capabilities </h2>
            </div>
            <div className="flex flex-col sm:flex-row items-center justify-center px-[2%]">
                {benefitData.map((item) => 
                    <div key={item.cardId} onClick={() => modalOpenHandler(item)}>
                       <Card imgsrc={benefits_description[item.cardId]['imgsrc']} 
                        title={benefits_description[item.cardId]['title']} 
                        summary={benefits_description[item.cardId]['summary']}/> 
                    </div>
                    
                )}
            </div>

            <ForwardNav to='/services'/>

            <ModalObject
                show={displayModal}
                className="rounded-3xl"
                onHide={modalCloseHandler}
                renderBackdrop={renderBackdrop}
            >   
            <div>
               <XMarkIcon className="hover:opacity-50 h-8 w-8 md:h-8 md:w-8 text-customBlueGray ml-auto mb-2" onClick={modalCloseHandler}/>
                <div className="flex flex-col items-center justify-center mb-4">
                  <h2 className="font-normal text-customBlueGray text-[19px] md:text-[23px]" >{benefits_description[cardId]['title']}</h2>  
                  <img className="h-[19rem] w-[20rem] sm:h-[20rem] sm:w-[20rem] xl:h-[27rem] xl:w-[27rem]" src={benefits_description[cardId]['imgsrc']}/>
                  <p className=" text-customBlueGray text-sm sm:text-md text-justify font-light">{benefits_description[cardId]['description']}</p>
                </div> 
            </div>
                
                
            </ModalObject>

        </div>
    )
}

export default Benefits;


