

interface CardProps {
    imgsrc: string;
    title: string;
    summary: string;
  }

function Card(props: CardProps) {
    return (
        <div className="mx-3 my-10 text-center card hover:opacity-50 transition-shadow duration-300 flex flex-col items-center justify-center">
            <img className=" h-[15rem] w-[15rem] sm:h-[13rem] sm:w-[15rem]  lg:h-[20rem] lg:w-[20rem] xl:h-[25rem] xl:w-[25rem] " src={props.imgsrc}/>
            <h2 className="font-normal text-customBlueGray">{props.title}</h2>
            <p className="text-customBlueGray text-[13px] font-light">{props.summary}</p>
        </div>
    )
}

export default Card;