import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './screens/Home';
import Benefits from './screens/Benefits';
import {BrowserRouter, HashRouter,Routes, Route} from "react-router-dom";
import Services from './screens/Services';
import About from './screens/About';
import Contact from './screens/Contact';

function App() {
  return (
    <BrowserRouter>

    <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/benefits" element={<Benefits/>}/> 
        <Route path="/services" element={<Services/>}/> 
        <Route path="/about" element={<About/>}/> 
        <Route path="/contact" element={<Contact/>}/> 
    </Routes>

    </BrowserRouter>
  );
}

export default App;