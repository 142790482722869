import { Link } from "react-router-dom";
import Button from "../components/Button";
import ForwardNav from "../components/ForwardNav";
import Navibar from "../components/Navibar";
// import NavigationBar from "../components/NavigationBar";
import homeimg1 from "../media/homeimg1.jpg";
import homeimg2 from "../media/homeimg2.jpg";


function Home(){
    return (
        <div className="flex flex-col justify-center ">
            <div>
               <Navibar/> 
            </div>
            
            <div className="flex flex-col justify-center mt-[4%] items-center px-4">
                <div  className="flex flex-col justify-center items-center  text-center text-customBlueGray z-30 md:-mb-10 md:w-[50%]">
                    <div className="px-[10%]">
                        <h1 className=" xl:text-[50px] lg:text-[45px] text-[30px] font-light text-customBlueGray">Transform Your Data Landscape with Our Cutting-edge Services</h1>
                        <h2 className="xl:text-[30px] lg:text-[28px] text-[18px] font-light text-customBlueGray">We build advanced cloud infrastructures for your data and AI projects</h2>
                    </div>
                    <div className="my-4">
                        <Link
                            to="/contact"
                        >
                            <Button title="Get in touch" customStyle="lg:text-[24px]"/>
                        </Link>
                    </div>
                     
                </div>
                <div className="flex flex-col sm:flex-row justify-center items-center my-[1%] space-x-15 lg:space-x-20 z-0">
                    <img className="h-[16rem] w-[20rem] sm:h-[18rem] sm:w-[23rem] lg:h-[25rem] lg:w-[33rem] xl:h-[30rem] xl:w-[42rem]" src={homeimg1}/>
                    <img className="h-[18rem] w-[18rem] sm:h-[18rem] sm:w-[18rem] lg:h-[26rem] lg:w-[27rem] xl:h-[32rem] xl:w-[32rem]" src={homeimg2}/> 
                </div>
            </div>
            <ForwardNav to='/benefits'/>
            
        </div>
    )
}

export default Home;