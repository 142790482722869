
type buttonStyle = {
    title: string;
    customStyle: any
}
function Button(props: buttonStyle){
    return (
        <div>
            <button className={`bg-customYellow hover:bg-customYellow text-customBlueGray font-light py-2 px-6 rounded-full sm:mt-[0.5rem] active:bg-customCard ${props.customStyle}`}>
                {props.title}
            </button>
        </div>
    )
}

export default Button;